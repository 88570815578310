import { ABOUT_DESCRIPTION } from "../../const";
import { ChatBtn } from "../chat-btn";
import { DescriptionSection } from "./description";
import { HeroSection } from "./hero";
export function AboutPage() {
  return (
    <main class="w-screen relative">
      <HeroSection title={"About"} />
      <DescriptionSection contents={ABOUT_DESCRIPTION} />
      <ChatBtn />
    </main>
  );
}
